




























































































































































































import Vue from 'vue'
import { VTooltip } from 'v-tooltip'

Vue.directive('tooltip', VTooltip)

enum SignUpStatus {
	Ok,
	Duplicate,
	Error,
}

export default Vue.extend({
	layout: 'registration',
	auth: false,
	data() {
		return {
			selectionProgress: 0,
			selectionInterval: null as any,
			hideGuestBtn: false,
			showNotificationSignUp: false,
			email: '',
			isSubmitting: false,
			privacyAccepted: false,
			signUpStatus: null as SignUpStatus|null,
			SignUpStatus,
		}
	},
	head(this: any) {
		const i18nSeo: any = this.$nuxtI18nSeo()

		i18nSeo.meta.push({
			hid: 'description',
			name: 'description',
			content: this.$t('meta.description').toString(),
		})

		i18nSeo.title = 'WithIn Finance'
		return i18nSeo
	},
	computed: {
		emailValid(): boolean {
			// eslint-disable-next-line max-len
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return this.email.length >= 7 && re.test(String(this.email).toLowerCase())
		},
		selection(): number {
			if (this.selectionProgress < 100) {
				return 0
			}
			if (this.selectionProgress < 200) {
				return 1
			}
			return 2
		},
	},
	watch: {
		// selection() {
		// 	clearTimeout(this.selectionTimout)
		// 	this.selectionTimout = setTimeout(() => {
		// 		this.selection = this.selection < 2 ? this.selection + 1 : 0
		// 	}, 10000)
		// },
	},
	mounted() {
		if (this.$auth.loggedIn) {
			this.$router.push('/feed')
		}

		const affiliateId = localStorage.getItem('affiliate_id') || null
		this.hideGuestBtn = !!affiliateId

		this.$nextTick(() => {
			// this.selection = 0
			this.selectionInterval = setInterval(() => {
				if (this.selectionProgress < 300) {
					this.selectionProgress += 1
				} else {
					this.selectionProgress = 0
				}
			}, 100)
		})

		// alert(window.location.hash);
		// if (window.location.hash === '#signup') {
		// 	this.showNotificationSignUp = true
		// }
	},
	methods: {
		signUp() {
			if (this.isSubmitting) {
				return
			}

			this.isSubmitting = true

			this.$axios.post('/api/notification-list', {
				email: this.email,
				language: this.$i18n.locale || 'de',
			}).then((resp) => {
				if (resp.data.status === 'ok') {
					this.signUpStatus = SignUpStatus.Ok
				} else if (resp.data.status === 'duplicate') {
					this.signUpStatus = SignUpStatus.Duplicate
				} else {
					// eslint-disable-next-line no-alert
					alert('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
				}
			}).catch(() => {
				this.signUpStatus = SignUpStatus.Error
			}).finally(() => {
				this.isSubmitting = false
			})
		},
	},
})
