import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7b1a0d61&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=7b1a0d61&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules_dev/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1a0d61",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/vercel/path0/components/elements/SvgIcon.vue').default,Footer: require('/vercel/path0/components/Footer.vue').default})
